.login {
  ion-input {
    --background: #ffffff;
    color: #000000;
    max-width: 240px;
    margin: 0.5rem auto 0.2rem;
  }

  ion-label {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
