@import "src/assets/scss/abstract/variables";

.question-text {
    margin: auto auto 2rem;
    max-width: 545px;

    @media (min-width: $breakpoint-tablet) {
        text-align: center;
    }
}

.question-title {
    font-weight: bold;
    margin-bottom: 1.4rem;

    @media (min-width: $breakpoint-tablet) {
        margin-top: 40px;
    }
}

.fade-enter .question-text {
    opacity: 0;
    transform: translateX(-100%);
}

.fade-enter-active .question-text {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit .question-text {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit-active .question-text {
    opacity: 0;
    transform: translateX(100%);
}

.fade-enter-active .question-text,
.fade-exit-active .question-text {
    transition: opacity 500ms, transform 500ms;
}

.question-icon {
    padding: 0 5px;
    font-size: 1.5rem;
}
