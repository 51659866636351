@import "src/assets/scss/abstract/variables";

.qna-container {
    border-bottom: 1px solid #3C3C4329;
    padding: 0 30px;

    @media (min-width: $breakpoint-tablet) {
        padding: 8px 30px;
    }

    &:first-of-type {
        border-top: 1px solid #3C3C4329;
    }
}

.icon-answer {
    font-size: 2rem;
    margin: auto 0;
    line-height: 100%;

    &--check {
        color: #37ad28;
    }

    &--close {
        color: #b30f1f;
    }
}
