@import "src/assets/scss/abstract/variables";

.landing-page {
  min-height: 100%;
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding-top: 4.65rem;
  padding-bottom: 2rem;
  background-image: linear-gradient(#063465, #0B3363, #B30F1F);
  display: grid;
}

img {
  display: block;
}

.heart {
  border-radius: 50%;
  margin: auto auto 2rem;
  max-width: 285px;
  height: auto;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 3.8rem;
  }
}

.title {
  margin: auto auto 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  max-width: 315px;

  @media (min-width: $breakpoint-tablet) {
    max-width: 565px;
    margin-bottom: 3.2rem;
  }
}

.mkt-logo {
  margin: auto auto 1.75rem;
}

.support {
  align-content: center;
  display: grid;
  justify-content: center;

  @media (min-width: $breakpoint-tablet) {
    justify-content: right;
    padding-right: 2rem;
  }
}
