@import "src/assets/scss/abstract/variables";

.link {
    color: $secondary-color;
    text-decoration: underline;
}

p {
    margin-top: 0;
}

.section-big {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-top: 1.375rem;

    @media (min-width: $breakpoint-tablet) {
        width: 100%;
    }
}

.bottom-buttons-container {
    padding: 15px;
    position: relative;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    @media (min-width: $breakpoint-tablet) {
        padding: 45px;
    }
}

.nowrap {
    white-space: nowrap;
}
