@import "src/assets/scss/abstract/variables";

.landing-page {
  min-height: 100%;
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding-top: 4.65rem;
  padding-bottom: 2rem;
  background-image: linear-gradient(#063465, #0B3363, #B30F1F);
  display: grid;
}

img {
  display: block;
}

.heart {
  border-radius: 50%;
  margin: auto auto 2rem;
  max-width: 285px;
  height: auto;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 3.8rem;
  }
}

.title {
  margin: auto auto 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  max-width: 315px;

  @media (min-width: $breakpoint-tablet) {
    max-width: 565px;
    margin-bottom: 3.2rem;
  }
}

.logo {
  display: none;

  @media (min-width: $breakpoint-tablet) {
    display: block;
    position: fixed;
    right: 15px;
    bottom: 25px;

    span {
      display: block;
      margin-bottom: -15px;
      font-size: 15px;
    }
  }
}

.p-button {
  font-size: 1.25rem;
  width: 100%;
  height: 3.5rem;

  &--secondary {
    @include secondary-button-theme;
  }
}

.button-holder {
  margin: auto;
  max-width: 480px;

  @media (min-width: $breakpoint-tablet) {
    display: grid;
    grid-gap: 1.8rem;
    grid-auto-flow: column;
    justify-content: center;
  }
}

.bottom-buttons-container--transparent {
  background-color: unset;
}
