@import "src/assets/scss/abstract/variables";

.alert-button {
    color: $secondary-color !important;
    font-size: 1rem !important;

    @media (min-width: $breakpoint-tablet) {
        font-size: 0.8rem !important;
    }

    &--bold {
        font-weight: bold !important;
    }
}

.alert {
    @media (min-width: $breakpoint-tablet) {
        font-size: 0.8rem;
    }

    .alert-wrapper {
        text-align: center;

        h2 {
            font-size: 1rem !important;
        }
    }

    .alert-message {
        font-size: 1rem;
    }
}
