@import "src/assets/scss/abstract/variables";

.p-button {
    font-size: 1.25rem;
    width: 100%;
    height: 3.5rem;
    @include primary-button-theme;

    &--secondary {
        @include secondary-button-theme;
    }
}

.button-holder {
    margin: auto;
    max-width: 480px;

    @media (min-width: $breakpoint-tablet) {
        display: grid;
        grid-gap: 1.8rem;
        grid-auto-flow: column;
        justify-content: center;
    }
}


