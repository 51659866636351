@import "src/assets/scss/abstract/variables";

#root {
    height: 100%;
}

body {
    font-family: sans-serif;
    font-size: $primary-font-size;
    height: 100%;
}

html {
    height: 100%;
}

.main {
    --padding-top: 1.2rem;
    --padding-bottom: 1.875rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.section {
    padding: 0 30px;
}
