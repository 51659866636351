@import "src/assets/scss/abstract/variables";

.header {
    @include secondary-theme;
    display: flex;
    font-weight: bolder;
    --min-height: 44px;

    &__title {
        font-size: 1.0625rem;
    }
}
