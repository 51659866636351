@import "src/assets/scss/abstract/variables";

.background-heart {
    position: fixed;
    bottom: 0px;
    height: 255px;
    width: 255px;
    left: 50%;
    transform: translate(-50%, 0);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url(/assets/heart-small.svg);

    @media (min-width: $breakpoint-tablet) {
        height: 380px;
        width: 380px;
        bottom: 3.5rem;
        background-image: url(/assets/heart-big.svg);
    }
}

ion-grid {
    display: flex;
    flex-direction: column;
    height: 100% !important;
    padding-bottom: 3rem;

    @media (orientation: landscape) {
        padding-bottom: 1rem;
    }
}

.history-container {
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
    margin-bottom: 5rem;

    @media (min-width: $breakpoint-tablet) {
        margin-bottom: 8rem;
    }
}

.icon-heart {
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $breakpoint-tablet) {
        height: 700px;
        width: 700px;
    }
}

.item-enter {
    opacity: 0;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 50ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 50ms ease-in;
}

.fade-enter {
    opacity: 0;
    transform: translateX(-5%);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(5%);
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 100ms, transform 100ms;
}

.grow-enter {
    opacity: 0;
    transform: scale(0.9);
}

.grow-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.grow-exit {
    opacity: 1;
}

.grow-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
