@import "src/assets/scss/abstract/variables";

.answer-button {
    @include primary-button-theme;
    display: block;
    font-size: 1.25rem;
    height: 3.5rem;

    @media (min-width: $breakpoint-tablet) {
        max-width: 250px;
    }
}

.answer-button--secondary {
    @include secondary-button-theme;
}

.answer-buttons {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr;
    max-width: 545px;
    margin: auto auto 20px;

    @media (min-width: $breakpoint-tablet) {
        margin-bottom: 75px;
    }
}
