$primary-color: #b30f1f;
$secondary-color: #063465;
$primary-font-size: 1.0625rem;
$breakpoint-tablet: 768px;
$breakpoint-wide: 1200px;
$height-small: 600px;

@mixin primary-theme {
  color: #ffffff;
  --background: $primary-color;
  background-color: $primary-color;
}

@mixin secondary-theme {
  color: #ffffff;
  --background: $secondary-color;
  background-color: $secondary-color;
}

@mixin button-default {
  @media (min-width: $breakpoint-tablet) {
    width: 310px;
    height: 45px;
  }
  @media (min-width: 1500px) {
    width: 450px;
    height: 60px;
  }

  font-size: 1.2rem;
  font-weight: 600;
  text-transform: none;
  --border-radius: 10px;
  border-radius: 10px;
  letter-spacing: initial;
}

@mixin primary-button-theme {
  @include button-default;
  @include primary-theme;
}

@mixin secondary-button-theme {
  @include button-default;
  --background: $secondary-color;
  color: $secondary-color;
  background-color: #eeeeee;
}

.suggestion-text {
  margin-top: 10px;
  color: $primary-color;
  font-style: italic;
  font-weight: bold;
}

.align-center {
  align-items: center;
}
