.popup {
  display: grid;
  align-items: end;
  justify-content: center;
  height: 100%;
  padding: 0 50px;
  font-weight: bold;
  overflow-y: auto;
  overflow-x: hidden;

  p {
    text-align: center;
  }
}

.popup-button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
