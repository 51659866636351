@import "src/assets/scss/abstract/variables";

.fab-container {
    margin: 1rem;

    @media (min-width: $breakpoint-tablet) {
        margin: 2.5rem;
    }
}

.fab-button {
    font-size: 1.5rem;
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
}

.fab-button--info {
    font-size: 2rem;
    border: 2px solid $primary-color;
    border-radius: 50%;
    margin-left: 0;

    @media (min-width: $breakpoint-tablet) {
        font-weight: lighter;
        border: 3px solid $primary-color;
    }

    ion-label {
        color: $primary-color;
    }
}
