@import "src/assets/scss/abstract/variables";

.suggestion-result-box {
  padding: 0.375rem;
  border-radius: 10px;
  background-image: linear-gradient($primary-color, $secondary-color);
  max-width: 400px;
  margin: auto auto 20px;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
  }

  &__inner {
    color: #fff;
    padding: 1rem;
    border: 2px solid #fff;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.5rem;
    position: relative;
  }
}

.s-title {
  margin-bottom: 0.625rem;
  font-weight: bold;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

.extra-info {
  position: absolute;
  right: -5px;
  top: -5px;
  border: 2px solid white;
  font-size: 18px;
  line-height: 1;
  width: 22px;
  border-radius: 50%;
  background-color: #ab1123;
}
